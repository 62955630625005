<template>
<v-container>
    <v-row>
        <v-col cols="12" md="6">
            <sign-up-card @success="redirect"/>
        </v-col>
        <v-col cols="12" md="6">
            <sign-in-card @success="redirect"/>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {mapState} from "vuex";
import SignInCard from '../components/Sections/SignInCard.vue';
import SignUpCard from '../components/Sections/SignUpCard.vue';
export default {
  components: { SignUpCard, SignInCard },
    name: 'Account',
    computed : {
        ...mapState({
            authToken : state => state.authToken
        })
    },
    created(){
        this.redirect()
    },

    methods:{
        redirect(){
            if (this.authToken) {
                this.$router.push('/')
            }
        }
    }
}
</script>
